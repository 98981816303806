
import { defineComponent, ref, onMounted, computed, ComputedRef, watch } from 'vue'
import formatCurrency from '@/filters/CurrencyFormat'
import Button from '@/components/UI/Button.vue'
import { ActionTypes as userCarsActionTypes } from '@/store/userCars/actions'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import { savedCarsType } from '@/types'
import Loader from '@/components/UI/Loader.vue'
import Popup from '@/components/Popup.vue'
import router from '@/router'
import getTranslatedBodyType from '@/filters/getTranslatedBodyType'
import getTranslatedFuelType from '@/filters/getTranslatedFuelType'
import { USER_STATUS } from '@/composables/staticValues'
import toLowerCase from '@/filters/toLowerCase'
export default defineComponent({
  name: 'Home',
  setup() {
    const open = ref()
    const route = useRoute()
    const store = useStore()
    const showPopup = ref(false)
    const deleteCarId = ref(0)
    const imagesURl = process.env.VUE_APP_IMG_URL
    const loader = computed(() => store.state.userCars.loader)
    const carOrderedTrue = computed(
      () => toLowerCase(store.state.auth.currentUser.userStatus) === USER_STATUS.CAR_ORDERED
    )
    const compareCarsData: ComputedRef<savedCarsType[]> = computed(() => store.state.userCars.compareCarsData)
    const compareCarsStatus = computed(() => store.state.userCars.compareCarsStatus)
    const initialize = async () => {
      if (route.query.id) {
        const compIds = Array.isArray(route.query.id) ? route.query.id : [route.query.id]
        await store.dispatch(userCarsActionTypes.GET_CAR_ORDER_DETAILS, compIds)
      }
    }

    onMounted(initialize)
    const optionsOpen = (index) => {
      if (open.value === index) {
        open.value = null
      } else {
        open.value = index
      }
    }
    const duplicateCar = async (item) => {
      const carBody = {
        vehicleId: item.vehicleDetails.unique_identity_101,
        monthlyCost: item.netMonthlyCost,
        carPrice: 1000,
        co2Emission: item.co2Emission,
        status: false,
        colors: item.interiorColor,
        options: item.options, // Needs to be changed in further steps
        mandatoryAccessories: item.mandatoryAccessories,
        otherAccessories: item.otherAccessories,
        chargingFacilities: item.chargingFacilities
      }
      const res = await store.dispatch(userCarsActionTypes.SAVE_CAR_ORDER, carBody)
      const id = res.carOrderResponse.id
      if (route.query.id) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const carIds: any = Array.isArray(route.query.id) ? [...route.query.id] : [route.query.id]
        carIds.push(id)
        router.push({ name: 'CompareCars', query: { id: carIds } })
        open.value = false
      }
    }

    const closePopup = () => {
      showPopup.value = false
    }

    const configureCar = async (savedCar) => {
      // allOptionId's
      const optionIds = savedCar.options
        .map((e) => {
          return [e, ...e['children']]
        })
        .flat()
        .map((item) => item['optionId'].toString())
      // userSelectedOptions
      const userSelectedOptions = savedCar.options.map((e) => {
        return e['optionId'].toString()
      })
      // nestedOptions
      const nestedOptions = {}
      savedCar.options.forEach((item) => {
        if (!nestedOptions[item['optionId']]) {
          nestedOptions[item['optionId']] = []
          item['children'].forEach((e) => nestedOptions[item['optionId']].push(e['optionId'].toString()))
        }
      })
      store.state.options.nestedOptions = nestedOptions
      sessionStorage.setItem('nestedOptions', JSON.stringify(nestedOptions))
      const selectedOptions = {
        selectedOptions: optionIds,
        userSelectedOptions: userSelectedOptions,
        requiredChoices: [],
        option: '',
        action: 'added',
        otherAccessories: savedCar.otherAccessories,
        chargingFacilities: savedCar.chargingFacilities
      }
      await sessionStorage.setItem('optionsConfigurationData', JSON.stringify(selectedOptions))
      await router.push({
        name: 'Step3',
        params: {
          vehicleId: savedCar.vehicleDetails.unique_identity_101,
          carOrderId: savedCar.id
        }
      })
    }
    const onProceed = async () => {
      showPopup.value = false
      open.value = false
      if (route.query.id && route.query.id.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const carIds: any = await [...route.query.id]
        const deletedIndex = await carIds.findIndex((e) => +e === +deleteCarId.value)
        await carIds.splice(deletedIndex, 1)
        await store.dispatch(userCarsActionTypes.DELETE_SAVED_CAR, [deleteCarId.value])
        if (carIds.length === 0) {
          await router.push({ name: 'Dashboard' })
        } else {
          await router.replace({ name: 'CompareCars', query: { id: carIds } })
        }
      }
    }
    const checkIfEv = (comparisionCars) => {
      const isEv = comparisionCars.some((car) => {
        const carType = car.vehicleDetails['type_48602'] || car.vehicleDetails['fuel_type_8702']
        return carType == 'electric' || carType == 'plug_in_hybrid'
      })
      return isEv
    }
    watch(
      () => route.query.id,
      () => {
        initialize()
      }
    )
    const deleteCar = (item: savedCarsType) => {
      deleteCarId.value = item.id
      showPopup.value = true
    }
    return {
      formatCurrency,
      open,
      optionsOpen,
      duplicateCar,
      deleteCar,
      compareCarsData,
      compareCarsStatus,
      imagesURl,
      loader,
      showPopup,
      closePopup,
      onProceed,
      configureCar,
      getTranslatedBodyType,
      getTranslatedFuelType,
      carOrderedTrue,
      checkIfEv
    }
  },
  components: {
    Button,
    Loader,
    Popup
  }
})
